import React, { Fragment, useState } from "react";
import { makeStyles, Box, Grid, AppBar, Button, ButtonBase, createStyles, Dialog, Toolbar } from "@material-ui/core";
import { useReduxSelector } from "../../../store/selectors";
import { GOOGLE_API_KEY } from "../../../utils/secrets";
import { $createNewRealEstate } from "../../../store/thunks/real-estate.thunk";
import { useRouter } from "next/router";
import { CreateForms } from "@onpreo/forms";
import noPipelineQuiz from "./no-pipeline-quiz.json";
import design from "./design.json";
import { useAppDispatch } from "src/store";
import { getContact } from "../../../store/selectors/contact.selectors";
import { $loadWorkspaceSession } from "src/store/thunks/session.thunk";

const useStyles = makeStyles(theme =>
    createStyles({
        background: {
            background: "#F8F9FE",
            height: "100vh"
        },
        headerImg: {
            verticalAlign: "middle",
            borderStyle: "none",
            marginTop: 16,
            marginLeft: 16,
            width: 74
        }
    })
);

const NewLeadCTA = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const currentContact = useReduxSelector(getContact);

    const handleClickOpen = () => setOpen(true);
    const handleClose = async () => {
        await dispatch($loadWorkspaceSession());
        setOpen(false);
    };

    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12}>
                    <Button variant="contained" color={"secondary"} onClick={handleClickOpen}>
                        Jetzt Immobilie anlegen
                    </Button>
                </Grid>
            </Grid>

            <Dialog fullScreen open={open} onClose={handleClose} className={classes.background}>
                <AppBar color={"transparent"} style={{ boxShadow: "none" }}>
                    <Toolbar>
                        <Grid container justifyContent={"space-between"} style={{ width: "100%" }}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img alt={"onpreo"} className={classes.headerImg} src={"/logo.png"} />
                            </Box>
                            <Grid container justifyContent={"flex-end"} item xs={2}>
                                <ButtonBase color="secondary" onClick={handleClose}>
                                    Prozess beenden
                                </ButtonBase>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box padding={16} className={classes.background}>
                    <CreateForms
                        onCreate={$createNewRealEstate}
                        quiz={noPipelineQuiz}
                        design={design}
                        envVar={GOOGLE_API_KEY}
                        hasValuation={"disabled"}
                        hasAddress={"enabled"}
                        preOwner={{ name: currentContact.meta.name, email: currentContact.meta.email, phone: currentContact.meta.phone }}
                        onAfterFinish={handleClose}
                    />
                </Box>
            </Dialog>
        </Fragment>
    );
};

export default NewLeadCTA;
